@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}

.homepage-container {
	padding-top: 25px;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.homepage-title {
	width: 85% !important;
	float: left;
}

.homepage-subtitle {
	width: 85% !important;
	float: right;
}

.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}

.homepage-image-container {
	width: 450px;
	height: 450px;
}

.homepage-image-wrapper {
	overflow: hidden;
	transform: rotate(3deg);

	background: #ffffff;
	display: inline-block;
	margin: 55px 75px 30px;
	padding: 15px 15px 30px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.homepage-image-wrapper img {
	width: 100%;
	display: block;
}

.homepage-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
	display: flex;
}

.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
}

.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	margin-left: -30px;
}

.homepage-article {
	padding-bottom: 5px;
}

.homepage-works {
	flex-basis: 600px;
	padding-top: 30px;
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title {
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
	}

	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}
