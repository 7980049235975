@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300&family=Ysabeau+Infant:ital,wght@1,100&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Hubballi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import "./data/styles.css";

body {
	margin: 0;
	font-family: var(--primary-font);
	font-size: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
